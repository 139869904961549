import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const TemplateMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "TemplateTemplateNavigationTitle",
        path: "/admin/template",
        page: "Templates",
        subpage: "AssessmentTemplates",
      },
      {
        id: "TemplateFormNavigationTitle",
        path: "/admin/template/form",
        page: "Templates",
        subpage: "AssessmentTemplates",
      },
    ]}
  />
);

export default TemplateMenu;
