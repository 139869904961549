import React, { useState, useEffect } from "react";
import { Table, Card, Space, Upload } from "lib";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  TablePaginationConfig,
} from "antd";
import { Layout } from "layout";
import { AssessmentTemplatesClient, AssessmentTemplateBriefDto } from "@api";
import { showError, Download } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import TemplateMenu from "@components/Admin/Template/TemplateMenu";
import { PlusOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { ACTION, cannot, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";

const defaultOrderBy = "created";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(
    SECTION.Admin,
    "Templates",
    "AssessmentTemplates"
  );
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const assessmentTemplatesClient = new AssessmentTemplatesClient();

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [state, setState] = useState({
    items: [] as AssessmentTemplateBriefDto[],
    pagination: defaultPagination,
    loading: false,
  });
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "AssessmentTemplates")) return;

    setState({
      ...state,
      loading: true,
    });
    try {
      const res =
        await assessmentTemplatesClient.getAssessmentTemplatesWithPagination(
          _keywords ?? keywords,
          params.pagination.current,
          params.pagination.pageSize,
          capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
          (params.sortOrder ?? defaultSortOrder) == "ascend"
        );
      setState({
        ...state,
        items:
          (res.items as AssessmentTemplateBriefDto[]) ||
          ([] as AssessmentTemplateBriefDto[]),
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
        loading: false,
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
      });
      showError(err);
    }
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleDownloadDescs() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await assessmentTemplatesClient.exportDescriptions();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadDescs(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await assessmentTemplatesClient.uploadDescriptions({
        data: f,
        fileName: f.name,
      });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleDownloadTemplates() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await assessmentTemplatesClient.export();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadTemplates(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await assessmentTemplatesClient.upload({
        data: f,
        fileName: f.name,
      });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleAddAlias() {
    try {
      const values = await form.validateFields();
      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticCreatingMessage" />
        );
        try {
          await assessmentTemplatesClient.create(values);
          messageApi.success(
            <FormattedMessage id="StaticCreateSuccessMessage" />
          );
          setCreateModalVisible(false);
          form.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {}
  }

  function handleCancelAddAlias() {
    setCreateModalVisible(false);
    form.resetFields();
  }

  function handleDeleteAlias(id: number) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmDeleteMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticDeletingMessage" />
        );
        try {
          await assessmentTemplatesClient.delete(id);
          messageApi.success(
            <FormattedMessage id="StaticDeleteSuccessMessage" />
          );
          fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    //   defaultSortOrder,
    // },
    {
      // title: "Assessment Template",
      title: <FormattedMessage id="TemplateTableHeaderAssessmentTemplate" />,
      dataIndex: "alias",
      key: "alias",
      sorter: true,
    },
    {
      // title: "Time added",
      title: <FormattedMessage id="TemplateTableHeaderTimeAdded" />,
      dataIndex: "created",
      key: "created",
      sorter: true,
      defaultSortOrder,
      render: (d: Date) => <>{d?.toLocaleString()}</>,
    },
    {
      // title: "Action",
      title: <FormattedMessage id="TemplateTableHeaderAction" />,
      key: "action",
      render: (text, record: AssessmentTemplateBriefDto) =>
        record.id && auth.can(ACTION.Delete, "AssessmentTemplates") ? (
          <Button
            danger
            ghost
            onClick={() => handleDeleteAlias(record.id as number)}
          >
            <FormattedMessage id="StaticDeleteButton" />
          </Button>
        ) : null,
    },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="TemplateTemplateNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <TemplateMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={handleKeywordsSearch}
              >
                <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords}  />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Space>
            <Space wrap>
              {auth.can(ACTION.Create, "AssessmentTemplates") ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateModalVisible(true);
                  }}
                >
                  <Space>
                    <PlusOutlined />
                    <FormattedMessage id="TemplateTemplateContentAddAliasButton" />
                  </Space>
                </Button>
              ) : null}
              {auth.can(ACTION.Download, "AssessmentTemplates") ? (
                <Button type="primary" onClick={handleDownloadTemplates}>
                  <FormattedMessage id="TemplateTemplateContentDownloadButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "AssessmentTemplates") ? (
                <Upload action={handleUploadTemplates}>
                  <Button type="primary">
                    <FormattedMessage id="TemplateTemplateContentUploadButton" />
                  </Button>
                </Upload>
              ) : null}
              {auth.can(ACTION.Download, "AssessmentTemplateDescriptions") ? (
                <Button type="primary" onClick={handleDownloadDescs}>
                  <FormattedMessage id="TemplateTemplateContentDownloadDescButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "AssessmentTemplateDescriptions") ? (
                <Upload action={handleUploadDescs}>
                  <Button type="primary">
                    <FormattedMessage id="TemplateTemplateContentUploadDescButton" />
                  </Button>
                </Upload>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      <Modal
        visible={createModalVisible}
        title={<FormattedMessage id="TemplateTemplateModalAddAliasTitle" />}
        okText={<FormattedMessage id="StaticCreateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleAddAlias}
        onCancel={handleCancelAddAlias}
        destroyOnClose={true}
      >
        <Form layout="horizontal" form={form}>
          <Form.Item
            name="alias"
            label={<FormattedMessage id="TemplateTemplateModalAliasLabel" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="TemplateTemplateStaticMissingAliasRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
